<template>
    <b-modal ref="addModal" 
        centered
        :title="title && title"
        :ok-title="$t(tKey + 'save')"
        @ok.prevent="create"
        size="lg"
    >
        <b-form-group v-for="f in fieldsAttr"
            :key="f.key"
            :label="$t(`${tKey}${f.key}.label`)"
            :label-for="'input-' + f.key"
        >
            <b-form-checkbox v-if="f.type === 'checkbox'"
                v-model="fields[f.key]"
            >
                {{ $t(`${tKey}${f.key}.label`) }}
            </b-form-checkbox>
            <b-form-select v-else-if="f.type === 'select'"
                :id="'input-' + f.key"
                v-model="fields[f.key]"
                :options="f.range"
                required
            />
            <b-form-input v-else
                :id="'input-' + f.key"
                v-model="fields[f.key]"
                :type="f.type"
                :placeholder="$t(`${tKey}${f.key}.label`)"
                :state="validateState(f.key)"
                required
            />
            <b-form-invalid-feedback v-for="v in f.validations" :key="v">
                <span v-if="!$v.fields[f.key][v]">
                    {{ $t(`${tKey}${f.key}.${v}`) }}
                </span>
            </b-form-invalid-feedback>
        </b-form-group>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate"   
import { required, maxLength, email } from 'vuelidate/lib/validators'
export default {
    name: "AddModal",
    props: {
        title: { type: String, required: false },
    },
    mixins: [validationMixin],
    data() {
        return {
            tKey: 'admin.modal.',
            fields: {
                firstname: null,
                lastname: null,
                email: null,
                username: null,
                role: 3,
            },
        }
    },
    computed: {
        fieldsAttr() {
            return [
                {
                    key: 'firstname',
                    type: 'text',
                    validations: ['required', 'maxLength'],
                },
                {
                    key: 'lastname',
                    type: 'text',
                    validations: ['required', 'maxLength'],
                },
                {
                    key: 'email',
                    type: 'email',
                    validations: ['required', 'maxLength', 'email'],
                },
                {
                    key: 'username',
                    type: 'text',
                    validations: ['required', 'maxLength'],
                },
                {
                    key: 'role',
                    type: 'select',
                    range: this.rolesOptions,
                }
            ]
        },
        rolesOptions() {
            return [
                {
                    text: this.$t(`admin.roles.DmoAdmin`),
                    value: 2,
                },
                {
                    text: this.$t(`admin.roles.DmoEmployee`),
                    value: 3,
                },
                {
                    text: this.$t(`admin.roles.Partner`),
                    value: 4,
                }
            ]
        },
        validation() {
            return {
                fields: {
                    firstname: {
                        required,
                        maxLength: maxLength(255), 
                    },
                    lastname: {
                        required,
                        maxLength: maxLength(255), 
                    },
                    username: {
                        required,
                        maxLength: maxLength(255), 
                    },
                    email: {
                        required,
                        maxLength: maxLength(255), 
                        email,
                    },
                }
            }
        },
    },
    methods: {
        show() {        
            this.$refs.addModal.show()
        },
        create() {
            this.$v.fields.$touch()
            if (this.$v.fields.$anyError) return   
            this.$emit('save', this.fields)
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.fields[name]
            return $dirty ? !$error : null
        },
    },
    validations() {
        return this.validation
    },
}
</script>

<style lang="scss">
.hidden {
    display: none;
}
</style>